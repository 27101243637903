<template>
    <div class="view-blogger-route" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="tripList">{{ translates.navigation.button_route_trips[lang] }}</div> 
            > {{ trip.name }}
        </nav>
        <div class="view-blogger-route__trip-info" v-if="translates && translates[langUrl]">
            <div class="view-blogger-route__main-container">
                <div v-if="trip.pictures && trip.pictures.length > 0" class="view-blogger-route__photo-container">
                    <div 
                        v-if="trip.pictures[0] !== '' && trip.pictures.length <= 1"
                        :style="`background-image: url(${imageSrc(trip.pictures[0])})`"
                        class="view-blogger-route__solo-main-photo"
                    ></div>
                    <v-carousel
                        v-if="trip.pictures && trip.pictures.length > 1"
                        class="custom-carousel"
                        hide-delimiter-background
                        hide-delimiters
                        :show-arrows="isDesktop ? true : false"
                        height="450px">
                        <v-carousel-item
                            v-for="(picture,i) in trip.pictures"
                            :key="'carousel-1-' + i"
                            :src="imageSrc(picture)"
                        />
                    </v-carousel>
                    <div
                        v-if="!isDesktop"
                        class="view-blogger-route__solo-main-photo__action view-blogger-route__solo-main-photo__action_back"
                        @click="back()">
                        <img src="../../assets/BackArrow.svg" class="view-blogger-route__solo-main-photo__action__icon" />
                    </div>
                </div>
                <div class="view-blogger-route__main-padding">
                    <div class="view-blogger-route__level-description" v-if="trip.level && summary.qualified < qualifiedQuizCount">
                        <div class="view-blogger-route__level-description__level">
                            <span>
                                <img src="../../assets/lock.svg" />
                                <span>{{ TRIP_LEVEL_TITLE[trip.level] }}</span>
                            </span>
                            <div>{{ translates[langUrl].levelDescription__level[lang] }}</div>
                        </div>
                        <div 
                            class="view-blogger-route__level-description__text"
                        >
                            <span v-html="translates[langUrl].levelDescription__text_1[lang]"></span>
                            {{ TRIP_LEVEL_TITLE[trip.level] }}
                            <span v-html="translates[langUrl].levelDescription__text_2[lang]"></span>
                            {{ qualifiedQuizCount  }}
                            <span v-html="translates[langUrl].levelDescription__text_3[lang]"></span>
                        </div>
                        <div class="view-blogger-route__level-description__subtext">
                            <BaseButton
                                class="button-fill"
                                @click="linkCopy">
                                {{ translates[langUrl].levelDescription__button[lang] }}
                            </BaseButton>
                            <div v-html="translates[langUrl].levelDescription__subtext[lang]" />
                        </div>
                    </div>
                    <MainInfo style="margin-top:20px" />
                    <CalculatorRoute
                        ref="booking"
                        v-if="!isDesktop"
                        :trip-id="trip._id"
                        :bloggers-page="bloggersPage"
                        :qualified-quiz-count="qualifiedQuizCount"
                        @tripRequest="tripRequest"
                        :showButton="summary.qualified >= qualifiedQuizCount"
                        :travelersNumber="+trip.groupSize"
                        :bloggerAward="+this.trip.price * 0.25"
                    />
                    <DetailMainBlock 
                        @writeToExpert="writeToExpert"
                    />
                    <div
                        v-if="photos && photos.lenght > 0"
                        ref="galery"
                        class="view-blogger-route__block-name">{{ translates[langUrl].blockName_photos[lang] }}</div>
                    <div v-if="photos && photos.lenght > 0" style="max-width: 1040px; overflow: hidden; border-radius: 10px;">
                        <WaterfallGrid
                            v-if="photos && photos.lenght > 0"
                            :photos="photos"
                        />
                    </div>
                    <BaseButton v-if="photos && photos.lenght > 0" style="width: 326px; margin-top: 40px">{{ translates[langUrl].button_allPhotos[lang] }}</BaseButton>
                    <div
                        v-if="reviews.items && reviews.items.length > 0"
                        ref="reviews"
                        class="view-blogger-route__block-name">{{ translates[langUrl].blockName_reviews[lang] }}</div>
                    <Reviews />
                    <h3 v-if="trip.questions && trip.questions.length > 0 && trip.questions[0].question !== ''" class="view-blogger-route__block-name">{{ translates[langUrl].blockName_questions[lang] }}</h3>
                    <TripQuestions v-if="trip.questions && trip.questions.length > 0 && trip.questions[0].question !== ''" :items="trip.questions" />
                    <div v-if="isDesktop" ref="tripSidebarStop" class="view-blogger-route__presentation">
                        <div class="view-blogger-route__presentation__text">
                            <div class="view-blogger-route__presentation__title">{{ translates[langUrl].consultation_title[lang] }}</div>
                            <div class="view-blogger-route__presentation__description">{{ translates[langUrl].consultation_description[lang] }}</div>
                        </div>
                        <BaseButton
                            class="button-fill"
                            type="download"
                            style="width: 300px; height: 52px;"
                            @click="consultation"
                        >{{ translates[langUrl].button_consultation[lang] }}</BaseButton>
                    </div>
                </div>
            </div>
            <div v-if="isDesktop" ref="tripSidebarContainer" class="view-blogger-route__sidebar__wrap">
                <div
                    ref="tripSidebar"
                    class="view-blogger-route__sidebar"
                    :class="{ 'view-blogger-route__sidebar-fixed': tripSidebarFixed, 'view-blogger-route__sidebar-absolute': tripSidebarAbsolute }">
                    <!-- <MainInfoOld :trip="trip" @click="$vuetify.goTo($refs.dates, options)" /> -->
                    <CalculatorRoute
                        :trip-id="trip._id"
                        :bloggers-page="bloggersPage"
                        @tripRequest="tripRequestOpen"
                        :showButton="summary.qualified >= qualifiedQuizCount"
                        :travelersNumber="+trip.groupSize"
                        :bloggerAward="+this.trip.price * 0.25"
                    />
                </div>
            </div>
        </div>
        <div v-if="isDesktop && translates[langUrl]" class="view-blogger-route__block-name">{{ translates[langUrl].blockName_similarTours[lang] }}</div>
        <Splide
            v-if="isDesktop && trips.length > tourSplideCounter"
            class="view-blogger-route__similar-tours_splide"
            :options="{ rewind: true, type: 'loop', perPage: tourSplideCounter, perMove: 1, pagination: false }"
        >
            <template v-for="(trip, index) in filteredtrips">
                <SplideSlide :key="index">
                    <MobilePreview
                        :trip="trip"
                        style="width: 335px; height: 98%;"
                        class="trip-preview-card__desktop"
                        :buttons="['favorites']"
                        @favoriteAdd="favoriteAdd"
                        @favoriteDelete="favoriteDelete"
                        @setStatus="setStatus" />
                </SplideSlide>
            </template>
        </Splide>
        <div v-if="trips.length <= tourSplideCounter" class="view-blogger-route__similar-tours">
            <template v-for="(trip, index) in filteredtrips">
                <MobilePreview
                    :key="index"
                    :trip="trip"
                    style="margin-right: 20px;"
                    class="trip-preview-card__desktop"
                    :buttons="['favorites']"
                    @favoriteAdd="favoriteAdd"
                    @favoriteDelete="favoriteDelete"
                    @setStatus="setStatus" />
            </template>
        </div>
        <div v-if="!isDesktop" class="view-blogger-route__similar-tours">
            <template v-for="(trip, index) in filteredtrips">
                <MobilePreview
                    :key="index"
                    :trip="trip"
                    :buttons="['favorites']"
                    :bloggers-list="bloggersPage"
                    @favoriteAdd="favoriteAdd"
                    @favoriteDelete="favoriteDelete"
                    @setStatus="setStatus" />
            </template>
        </div>
        <Chat
            :trip="trip"
        />
        <!-- <div v-if="!isDesktop" class="view-blogger-route__buttons">
            <BaseButton class="button-fill" style="width: 53%" @click="$vuetify.goTo($refs.booking, options)">{{ translates[langUrl].button_booking[lang] }}</BaseButton>
            <BaseButton style="width: 42%" @click="writeToExpert">{{ translates[langUrl].button_writeToExpert[lang] }}</BaseButton>
        </div> -->

        <BaseAlertDialog
            v-model="linkDialog"
            :buttonText="translates[langUrl].linkDialog__button[lang]"
            @click="linkDialog = false"
        >{{ translates[langUrl].linkDialog__text[lang] }}</BaseAlertDialog>

        <BasePopup 
            v-model="tripRequestPopup"
            width="600px">
            <div class="trip-request-popup">
                <h1 class="trip-request-popup__page-name">{{ trip.name }}</h1>
                <div class="trip-request-popup__comment">
                    <div v-if="tripRequest.start ">
                        <span>{{ translates[langUrl].label_tourDate[lang] }}:</span> <b>{{ tripRequest.start | moment('LL') }}</b>
                    </div>
                    <div>
                        <span>{{ translates[langUrl].bloggerAward[lang] }}:</span> <b><span v-html="priceFormated(tripRequest.bloggerAward, trip.currency)"></span></b>
                    </div>
                    <div>
                        <span>{{ translates[langUrl].label_tourPlaces[lang] }}:</span> <b>{{ tripRequest.bloggerPlaces }}</b>
                    </div>
                </div>
                <h1 class="trip-request-popup__page-name">{{ translates[langUrl].workshops[lang] }}</h1>
                <div class="trip-request-popup__comment">{{ translates[langUrl].workshops_comment[lang] }}</div>
                <BaseTextArea 
                    :placeholder="translates[langUrl].workshops_text[lang]"
                    v-model="tripRequest.workshops"
                />
                <BaseButton
                    class="button-fill"
                    width="100%"
                    v-if="summary.qualified >= qualifiedQuizCount"
                    :disbled="isLoading? true : false"
                    @click="tripRequestSave">
                    {{ translates[langUrl].button_sendRequest[lang] }}
                </BaseButton>
            </div>
        </BasePopup>
        <v-dialog
            v-model="popupConsultation"
            max-width="600px"
        >
            <PopupConsultation
                @close="closePopupConsultation"
            />
        </v-dialog>
    </div>
</template>

<script>
    import BaseButton from '../../components/common/BaseButton.vue';
    import TripAdvantages from '../../components/common/trip/Advantages.vue';
    import TripQuestions from '../../components/common/trip/Questions.vue';
    import TripGallery from '../../components/common/trip/Gallery.vue';
    import DesktopGallery from '../../components/common/trip/DesktopGallery.vue';
    import Organizer from '../../components/common/trip/Organizer.vue';
    import Reviews from '../../components/common/trip/Reviews.vue';
    import MainInfo from '../../components/common/trip/MainInfo.vue';
    import CalculatorRoute from '../../components/common/trip/CalculatorRoute.vue';
    import Benefits from '../../components/common/trip/Benefits.vue';
    import Arrivals from '../../components/common/trip/Arrivals.vue';
    import MobilePreview from '../../components/common/trip/PreviewCard.vue';
    import WaterfallGrid from '../../components/common/WaterfallGrid.vue';
    import Chat from '../../components/common/trip/Chat.vue';
    import PopupConsultation from '../../components/common/PopupConsultation.vue';
    import DetailMainBlock from '../../components/common/trip/DetailMainBlock.vue';
    import BaseAlertDialog from '../../components/common/BaseAlertDialog.vue';
    import BasePopup from '../../components/common/BasePopup.vue';
    import BaseTextArea from '../../components/common/BaseTextArea.vue';

    import store from '@/store';
    import moment from '@/plugins/moment';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { mapState } from 'vuex';
    import { imageSrc, SplideSlideCounter, priceFormated, isMobile } from '@/helpers';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import { TRIP_STATUS, TRIP_TYPE, TRIP_LEVEL, TRIP_LEVEL_TITLE, TRIP_LEVEL_QUALIFIED_COUNT, FEEDBACK_TYPE } from '@/vars';
    
    export default {
        name: 'Detail',
        metaInfo() {
            return {
                title: this.trip ? this.trip.name : ''
            }
        },
        components: {
            BaseButton,
            Organizer,
            DesktopGallery,
            Reviews,
            TripAdvantages,
            TripQuestions,
            MainInfo,
            CalculatorRoute,
            Benefits,
            Arrivals,
            MobilePreview,
            Splide,
            SplideSlide,
            WaterfallGrid,
            TripGallery,
            Chat,
            PopupConsultation,
            DetailMainBlock,
            BaseAlertDialog,
            BasePopup,
            BaseTextArea,
        },
        data: () => ({
            TRIP_LEVEL_TITLE,
            FEEDBACK_TYPE,
            priceFormated,
            imageSrc,
            isDesktop: false,
            hide: true,
            tripSidebarFixed: false,
            tripSidebarAbsolute: false,
            tripMenuFixed: false,
            tripMenuAbsolute: false,
            selectedMonth: null,
            lastMove: null,
            tourSplideCounter: 1,
            placesSplideCounter: 1,
            popupConsultation: false,
            popupParticipation: false,
            popupDownload: false,
            PopupNoPresentation: false,
            isLoading: false,
            feedback: {
                trip: null,
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
            },
            tripRequest: {
                trip: '',
                blogger: '',
                start: '',
                bloggerPlaces: '',
                bloggerAward: '',
                workshops: ''
            },
            secondsLeft: 0,
            intervalId: undefined,
            bloggersPage: false,
            linkDialog: false,
            tripRequestPopup: false,
            langUrl: '/trip/name',
            tripRequestLangUrl: '/operator/trip-requests/id'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('reviews', {
                reviews: state => state.entities
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('quiz', ['summary']),
            photos() {
                return this.trip.photos? this.trip.photos.map(item => item.picture) : [] || []
            },
            filteredtrips() {
                return this.trips.filter(item => {
                    if (!item.code || (this.trip.code && this.trip.code.toString() === item.code.toString())) {
                        return false;
                    }
                    return true;
                });
            },
            options () {
                return {
                    offset: 100
                }
            },
            polyline() {
                return this.trip.direction ? this.trip.direction.map(item => item.coordinates) : [];
            },
            centerCoordinates() {
                return this.trip.direction && this.trip.direction.length ? [
                    this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                        return sum + +item.coordinates[0]
                    }, 0) / this.trip.direction.length : 55.749451,
                    this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                        return sum + +item.coordinates[1]
                    }, 0) / this.trip.direction.length : 37.542824,
                ] : [];
            },
            inFavorites() {
                if (this.user._id) {
                    return this.user.favoriteTrips.indexOf(this.trip._id) >= 0;
                }
                return false;
            },
            quizLink() {
                return `${process.env.VUE_APP_CURRENT_URL}/quiz/blogger/${this.user.social || null}`;
            },
            qualifiedQuizCount() {
                switch(this.trip.level) {
                    case TRIP_LEVEL.EXPERT: 
                        return this.user.qualifiedQuizCountExpert || TRIP_LEVEL_QUALIFIED_COUNT.EXPERT;
                        break;
                    case TRIP_LEVEL.ADVANCED: 
                        return this.user.qualifiedQuizCountAdvanced || TRIP_LEVEL_QUALIFIED_COUNT.ADVANCED;
                        break;
                    default: 
                        return this.user.qualifiedQuizCount || TRIP_LEVEL_QUALIFIED_COUNT.BEGINNER;
                        break;
                }
            }
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            if (this.$route.name === 'trip-bloggers') {
                this.bloggersPage = true;
            }
            if (this.$route.params.code) {
                await store.dispatch('trips/getByCode', { code: this.$route.params.code });
                await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id } });
            }
            await this.getTrips();
            await store.dispatch('quiz/summary', { qualified: true });
            if(this.user._id) {
                await this.getBookings();
            }
            this.handleScroll();
            this.$root.$emit('preloaderHide');
        },
        async updated() {
            if (this.trip.code !== this.$route.params.code) {
                await store.dispatch('trips/getByCode', { code: this.$route.params.code });
                await store.dispatch('reviews/fetch', { filter: { trip: this.trip._id } });
            }
        },
        created () {
            // this.$router.go(0);
            window.addEventListener('scroll', this.handleScroll);
            this.tourSplideCounter = SplideSlideCounter(350, 1400);
            this.placesSplideCounter = SplideSlideCounter(260, 910, (0.3*innerWidth));
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            areYouLogin() {
                if(!this.user._id) {
                    false
                }
            },
            handleScroll() {
                if(this.isDesktop) {
                    // this.tripMenuAbsolute = (this.$refs.tripSidebarStop.getBoundingClientRect().top + this.$refs.tripSidebarStop.clientHeight) <= (this.$refs.tripMenuContainer.clientHeight);
                    // this.tripMenuFixed = !this.tripMenuAbsolute && (this.$refs.tripMenuContainer.getBoundingClientRect().top <= 0);
                    this.tripSidebarAbsolute = (this.$refs.tripSidebarStop.getBoundingClientRect().top + this.$refs.tripSidebarStop.clientHeight) <= (this.$refs.tripSidebar.clientHeight);
                    this.tripSidebarFixed = !this.tripSidebarAbsolute && (this.$refs.tripSidebarContainer.getBoundingClientRect().top <= 0);
                    this.$refs.tripSidebarContainer.click();
                }
            },
            async linkCopy() {
                navigator.clipboard.writeText(this.quizLink).then(() => this.linkDialog = true);
            },
            getDate() {
                let date = new Date(this.trip.arrival.start);
            },
            duration(arr) {
                let duration = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return duration
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            async tripList () {
                await this.$router.push({ name: 'blogger-routes' });
            },
            async writeToExpert() {
                if(this.user._id) {
                    this.$root.$emit('tripChatOpen');
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        this.$root.$emit('tripChatOpen');
                    });
                }
            },
            async back() {
                await this.$router.back();
            },
            async favoriteAdd({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteAdd', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await store.dispatch('trips/favoriteAdd', { id });
                        await store.dispatch('auth/fetch');
                        await this.getTrips();
                    });
                }
            },
            async favoriteDelete({ id }) {
                if (this.user._id) {
                    await store.dispatch('trips/favoriteDelete', { id });
                    await store.dispatch('auth/fetch');
                    await this.getTrips();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await store.dispatch('trips/favoriteDelete', { id });
                        await store.dispatch('auth/fetch');
                        await this.getTrips();
                    });
                }   
            },
            async setStatus({ id, status }) {
                await store.dispatch('trips/setStatus', { id, status });
                await this.getTrips();
            },
            monthSelected() {
                if(this.selectedMonth === null) {
                    return 'Ближайшие отправления'
                } else {
                    const string = moment(this.selectedMonth).format('MMMM YYYY');
                    return string[0].toUpperCase() + string.substring(1)
                }
            },
            authorization() {
                if(!this.user._id) {
                    this.$root.$emit('popupLoginShow');
                }
            },
            onResize() {
                this.isDesktop = !isMobile();
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { filter: {
                    ...this.filter,
                    type: TRIP_TYPE.HOST,
                    status: TRIP_STATUS.ACTIVE,
                }, sorting: this.sorting });
            },
            async getBookings() {
                await store.dispatch( 'bookings/fetch', {} );
            },
            async sendFeedback(type) {
                this.feedback = {
                    trip: this.trip._id,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    email: this.user.email,
                    type: type,
                    link: document.location.href
                };
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
            },
            async consultation() {
                if (this.user._id) {
                    await this.sendFeedback(FEEDBACK_TYPE.CONSULTATION);
                    this.openPopupConsultation();
                } else {
                    this.authorization()
                    this.$root.$once('authorized', async () => {
                        await this.sendFeedback(FEEDBACK_TYPE.CONSULTATION);
                        this.openPopupConsultation();
                    });
                }
            },
            async download() {
                this.$refs.dowloadProgram.click();
            },
            openPopupConsultation() {
                this.popupConsultation = true;
            },
            closePopupConsultation() {
                this.popupConsultation = false;
            },
            async tripRequestOpen({ start, bloggerPlaces, bloggerAward }) {
                this.tripRequest.trip = this.trip._id;
                this.tripRequest.blogger = this.user._id;
                this.tripRequest.start = start;
                this.tripRequest.duration = this.trip.programs.length;
                this.tripRequest.bloggerPlaces = bloggerPlaces;
                this.tripRequest.bloggerAward = bloggerAward;
                
                this.tripRequestPopup = true;
            },
            async tripRequestSave() {
                this.$root.$emit('preloaderShow');
                store.commit('tripRequests/SET_ENTITY', this.tripRequest);
                await store.dispatch('tripRequests/save');

                this.tripRequestPopup = false;
                this.$root.$emit('preloaderHide');
                
                await this.$router.push({ name: 'blogger-trips-my-tab', params: { tab: 'requests' } });
            }
        }
    }
</script>

<style lang="scss">
.view-blogger-route {
    width: 100%;
    background: #FFFFFF;
	margin-bottom: 10px;
	// overflow: hidden;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    padding-bottom: 60px;
    margin: auto;
    max-width: 1260px;
    &__main-padding {
        // display: flex;
        @media all and (max-width:768px) {
            padding:  0 20px;
        }
    }
    @media all and (max-width: 1400px) {
        margin: 0 20px;
    }
    &__level-description {
        background: #FFFFFF;
        box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
        border-radius: 12px;
        padding: 32px;
        margin: 32px 0 0 0;

        &__level {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            color: #273155;
            margin-bottom: 12px;
            @media all and (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
            }

            > span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 116px;
                background: #273155;
                border-radius: 5px;
                padding: 5px 10px;
                margin-right: 10px;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.015em;
                color: #FFFFFF;
                img {
                    height: 13px;
                    width: 13px;
                    margin-right: 4px;
                }
                @media all and (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
        }
        &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #273155;
            margin-bottom: 32px;
        }
        &__subtext {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            @media all and (max-width: 768px) {
                flex-direction: column;
                .button-fill {
                    width: 100%;
                    margin-bottom: 32px;
                }
            }

            > div {
                margin-left: 12px;
                font-weight: 400;
                font-size: 13px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #616D89;
                @media all and (max-width: 768px) {
                   margin-left: 0; 
                }
                
                > a {
                    color: #3107D8;
                    font-style: italic;
                }
            }
        }
    }
    &__trip-info {
        position: relative;
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        @media all and (min-width: 1400px) {
            justify-content: space-between;
        }
        @media all and (max-width:768px) {
            padding-top: 0;
        }
    }
    &__buttons {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #FFF;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 20px;
        z-index: 10;
    }

    &__main-container {
        position: relative;
        width: 65%;
        max-width: 950px;
        margin-right: 20px;
        // @media all and (max-width: 1400px) {
        //     width: calc(100% - 357px);
        // }
        @media all and (max-width:768px) {
            width: 100%;
            margin-right: 0;
        }
    }
    // &__yandexmap {
    //     width: 100%; 
    //     height: 400px; 
    //     margin-top: 38px;
    //     border-radius: 12px;
    //     overflow: hidden;
    // }
    &__photo-container {
        width: 100%;
        height: 450px;
        border-radius: 10px;
        overflow: hidden;
        @media all and (max-width:768px) {
            border-radius: 0;
        }
    }
    &__solo-main-photo {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media all and (max-width:768px) {
            position: relative;
            width: 100%;
            // height: 320px;
            &__action {
                position: absolute;
                display: flex;
                height: 32px;
                width: 32px;
                top: 40px;
                background: #FFF;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    cursor: pointer;
                }
                &_back {
                    left: 20px;
                }
                &_download {
                    right: 64px;
                }
                &_like {
                    right: 20px;
                }
            }
            &__icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
            &__text {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #FFFFFF;
            }
        }
    }
    &__menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        // padding: 0 7px;
        border-bottom: 1px solid #E5E6E9;
        color: #65676E;
        &-fixed {
            width: 75%;
            max-width: 1042px;
            position: fixed;
            top: 0;
            background-color: #fff;
            z-index: 100;
            @media all and (max-width: 1400px) {
                width: calc(100% - 357px);
            }
        }
        &-absolute {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #fff;
            z-index: 100;
        }
        &__item {
            padding: 40px 25px 16px;
            
            &:hover {
                color: #DA0A63;
                cursor: pointer;
                border-bottom: 2px solid #DA0A63;
                margin-bottom:  -2px;
            }
        }
    }
    &__block-name {
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #273155;
        margin-top: 70px;
    }
    &__presentation {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 112px;
        padding: 30px 30px 30px 25px;
        background: #F6F7F9;
        border-radius: 12px;
        justify-content: space-between;
        margin-top: 70px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            min-height: 220px;
            background: #F6F7F9;
            border-radius: 12px;
            padding: 16px 24px;
            margin: 40px 0;
        }
        &__text {
            display: flex;
            flex-direction: column;
            font-family: Inter;
            font-style: normal;
            letter-spacing: -0.02em;
            color: #3A4256;
        }
        &__title {
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            @media all and (max-width: 768px) {
                font-weight: 600;
                font-size: 22px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 12px;
            }
        }
        &__description {
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            @media all and (max-width: 768px) {
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 24px;
            }
        }
        &__button {
            width: 254px;
            height: 52px;
            @media all and (max-width: 768px) {
                width: 100%;
            }
        }
    }
    &__buttons__personality {
        margin-top: 30px;
        &__personal {
            width: 160px;
            height: 48px;
            margin-right: 6px;
            @media all and (max-width: 768px) {
                width: 124px;
                height: 36px !important;
            }
        }
    }
    &__sidebar {
        display: flex;
        justify-content: flex-end;
        z-index: 100;
        &__wrap {
            position: relative;
            width: 30%;
        }
        &-fixed {
            position: fixed;
            top: 10px;
            // right: calc(50% - 602px);
            right: calc(50% - 699px);
            // @media all and (max-width: 1400px) {
            //     right: 40px;
            // }
            width: 30%;
            @media all and (max-width: 1400px) {
                right: 25px;
                width: 29.8%;
            }
        }
        &-absolute {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    &__similar-tours {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        margin-right: -20px;
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-right: 0;
        }
    }
    &__similar-tours_splide {
        margin-top: 30px;
        @media all and (max-width: 1400px) {
            width: calc(100% - 40px);
        } 
        .splide__slide {
            align-items: center;
        }
    }
    &__offers {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        &__offer {
            display: flex;
            flex-direction: row;
            width: 326px;
            height: 114px;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(44, 47, 56, 0.08);
            border-radius: 10px;
            margin-right: 30px;
            padding: 30px 15px;
            &__picture {
                min-width: 30px;
                height: 30px;
                background: url(../../assets/Cards.svg) no-repeat;
                background-position: center;
                background-size: cover;
                margin-right: 20px;
            }
            &__text {
                font-size: 14px;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: #3A4256;
            }
            &__button {
                margin-top: 5px;
                color: #D80765;
                cursor: pointer;
            }
        }
        &__secondOffer {
            .view-blogger-route__offers__offer__picture {
                background: url(../../assets/CardsReverse.svg) no-repeat;
            }
        }
    }
    &__months {
        display: flex;
        flex-direction: row;
        margin-top: 30px;
        flex-wrap: wrap;
        gap: 10px;
        @media all and (max-width: 768px) {
            overflow: scroll;
            flex-wrap: nowrap;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-width: 140px;
            height: 70px;
            border: 1px solid #EBEBEB;
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            cursor: pointer;
            &__month {
                text-transform: capitalize;
            }
            &-active {
                color: #fff; 
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
            }
        }
    }
    &__gallery{
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        margin-top: 16px;
        padding-bottom: 7px;
        &::-webkit-scrollbar {
            display: none;
        }
        .splide__arrow {
            width: 40px;
            height: 40px;
            opacity: 1;
            padding: 12px;
            top: 40%;
            background: #fff;
        }
        .splide__arrow--prev {
            content: url(../../assets/BeforeArrow.svg);
            opacity: 1;
            left: 0;
        }
        .splide__arrow--next {
            content: url(../../assets/NextArrow.svg);
            right: 0;
        }
    }
    .v-window__prev, .v-window__next {
        background: #fff;
    }
    .mdi-chevron-right::before {
        content: url(../../assets/NextArrow.svg);
        margin: 2px 5px 15px;
        
    }
    .mdi-chevron-left::before {
        content: url(../../assets/BeforeArrow.svg);
        margin: 2px 5px 15px;
    }
    .download-link {
        position: absolute;
        width: 254px;
        height: 50px;
    }


}

.trip-request-popup {

    &__page-name {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0 12px;
        @media all and (max-width: 768px) {
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
        }
    }
    &__comment {
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        color: #616D89;
        @media all and (max-width: 768px) {
            font-weight: 600;
        }

        > div {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
<template>
    <v-dialog 
        v-on="$listeners"
        v-bind="$attrs"
        :max-width="width"
    >
        <v-card class="base-alert-dialog" >
            <v-card-title class="headline"></v-card-title>
            <v-card-text class="base-alert-dialog__text">
                <slot/>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <BaseButton @click="$emit('click')" class="mb-4">
                    {{ buttonText }}
                </BaseButton>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import BaseButton from './BaseButton'

export default {
    name: 'base-alert-dialog',
    inheritAttrs: false,
    components: {
        BaseButton
    },
    props: {
        buttonText: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '400'
        }
    },
}
</script>

<style lang="scss">
    .base-alert-dialog {
        &__text {
            font-family: IBM Plex Sans, Arial, sans-serif;
            text-align: center;
            color: #0D0D12;
            font-size: 16px;
        }

        &.v-card {
            border-radius: 10px;
        }
    }
</style>

<template>
    <div class="right-info-block" v-if="translates && translates[langUrl]">
        <ValidationObserver ref="validator">
            <div class="right-info-block__prices__info no-border">
                <div class="right-info-block__prices__info__text">
                    <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].label_tourDate[lang] }}</div>
                </div>
                <!-- <div style="border-bottom: 1px black solid;"> -->
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <DatePicker
                            :value="dateStart"
                            :label="translates[langUrl].fieldTitle_date[lang]"
                            @change="updatedateStart"
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                <!-- </div> -->
            </div>
            <div class="right-info-block__prices__info no-border">
                <div class="right-info-block__prices__info__text">
                    <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].label_duration[lang] }}</div>
                </div>
                <div class="right-info-block__prices__info__text__value">
                    <span v-if="trip && trip.programs">{{ trip.programs.length}} {{ lastDigitToWord(trip.programs.length) }}</span>
                </div>
            </div>
            <div class="right-info-block__prices__info">
                <div class="right-info-block__prices__info__text">
                    <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].label_tourPrice[lang] }}</div>
                </div>
                <div class="right-info-block__prices__info__text__value">
                    <span v-html="priceFormated(trip.price, trip.currency)"></span>
                </div>
            </div>
            <div class="right-info-block__prices__info no-border award">
                <div class="right-info-block__prices__info__text">
                    <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].bloggerAward[lang] }}</div>
                    <div class="right-info-block__prices__info__text awardComment">{{ translates[langUrl].label_yourReward[lang] }}</div>
                </div>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <AwardTextField 
                        v-model="bloggerAward"
                    />
                </ValidationProvider>
            </div>
            <div class="right-info-block__prices__info">
                <div class="right-info-block__prices__info__text">
                    {{ translates[langUrl].totalPrice[lang] }}
                </div>
                <div class="right-info-block__prices__info__value weight">
                    <span v-html="priceFormated(priceWithAward, trip.currency)"></span>
                </div>
            </div>
            <div class="right-info-block__prices__info no-border">
                <div class="right-info-block__prices__info__text">
                    {{ translates[langUrl].label_tourPlaces[lang] }}
                </div>
                <BaseCounter
                    :value="travelersNumber"
                    :max-value="+trip.groupSize"
                    @change="updateTraveler"
                />
            </div>
            <div class="right-info-block__prices__info">
                <div class="right-info-block__prices__info__text">
                    <div class="right-info-block__prices__info__text bloggerAward">{{ translates[langUrl].label_totalReward[lang] }}</div>
                </div>
                <div class="right-info-block__prices__info__value big">
                    <span v-html="priceFormated(totalAward, trip.currency)"></span>
                </div>
            </div>
        </ValidationObserver>
        <BaseButton
            style="height: 45px; width: 100%; margin-bottom: 10px;"
            class="button-fill"
            :disabled="!showButton"
            @click="tripRequest">{{ translates[langUrl].button_sendRequest[lang] }}
        </BaseButton>
        <div class="right-info-block__comment">
            {{ translates[langUrl].blockComment[lang] }}
        </div>
    </div>
</template>

<script>
    import DateSelect from './DateSelect.vue'
    import BaseButton from '../BaseButton.vue'
    import BaseCounter from '../BaseCounter.vue'
    import AwardTextField from '../AwardTextField.vue'
    import DatePicker from '../trip/RouteDatePicker.vue'
    import RangeDatePicker from '../trip/RouteRangeDatePicker.vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import { imageSrc, priceFormated, isMobile, lastDigitToWord } from '@/helpers';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS, BOOKING_TYPE } from '@/vars';
    import moment from '@/plugins/moment'

    export default {
        name: 'CalculatorRoute',
        components: {
            DateSelect,
            BaseButton,
            BaseCounter,
            AwardTextField,
            RangeDatePicker,
            DatePicker,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            tripId: {
                type: String,
                default: ''
            },
            bloggersPage: {
                type: Boolean,
                default: false
            },
            qualifiedQuizCount: {
                type: Number,
                default: 50
            },
            showButton: {
                type: Boolean,
                default: false
            },
            travelersNumber: {
                type: Number,
                default: 1
            },
            bloggerAward: {
                type: Number,
                default: 0
            }
        },
        data: () => ({
            imageSrc,
            lastDigitToWord,
            dates: [],
            dateStart: null,
            BOOKING_STATUS,
            priceFormated,
            lastMove: null,
            isMobile: false,
            langUrl: 'CalculatorRoute'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('trips', {
                trip: state => state.entity
            }),
            ...mapState('auth', ['user']),
            ...mapState('bookings', {
                bookings: state => {
                    return state.entities.filter(item => { return item.status !== BOOKING_STATUS.CANCELED })
                }
            }),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('quiz', ['summary']),
            priceWithAward() {
                return Math.round((+this.trip.price + +this.bloggerAward));
            },
            totalAward() {
                return this.bloggerAward * this.travelersNumber
            },
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        defaultValue() {
            if(this.trip) {
                this.travelersNumber = this.props.travelersNumber;
                this.bloggerAward = this.props.bloggerAward;
            }
        },
        mounted() {
            this.defaultValue
        },
        methods: {
            async validate() {
                const valid = await this.$refs['validator'].validate();
                return valid;
            },
            onResize() {
                this.isMobile = !isMobile();
            },
            updateTraveler(value) {
                this.travelersNumber = value;
            },
            cost() {
                return (this.travelersNumber * this.trip.price);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async tripRequest() {
                const valid = await this.$refs['validator'].validate();
                if (valid) {
                    this.$emit('tripRequest', { start: this.dateStart, bloggerPlaces: this.travelersNumber, bloggerAward: this.bloggerAward });
                }
            },
            updateDates(value) {
                this.dates = value;
            },
            updatedateStart(value) {
                this.dateStart = value;
            },
        }
    }

</script>

<style lang="scss">
.right-info-block {
    position: relative;
    max-width: 420px;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    z-index: 5;
    &__prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6px 16px;
        border-bottom: 1px solid #EBEBEB;
        // @media all and (max-width: 768px) {
        //     flex-direction: column;
        //     justify-content: flex-start;
        // }
        &__info {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            padding: 17px 0;
            border-bottom: 1px solid #EBEBEB;
            display: flex;
            justify-content: space-between;
            &.no-border {
                border-bottom: none;
            }
            &.award {
                padding-bottom: 7px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                color: #A1A4B1;
                &.bloggerAward {
                    // margin-top: 5px;
                    margin-bottom: 4px;
                }
                &.awardComment {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
            &__value {
                color: #273155;
                &.weight {
                    font-weight: 700;
                }
                &.big {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            &__date-picker {
                max-width: 140px;
            }
            &__comment {
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: -0.02em;
                color: #9FA5AD;
                @media all and (max-width: 768px) {
                    margin-bottom: 4px;
                }

                &__button {
                    margin-top: 8px;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                }
            }
        }
    }
    &__offers {
        display: flex;
        flex-direction: row;
        padding: 16px 0;
        border-bottom: 1px solid #EBEBEB;
        &__offer {
            display: flex;
            flex-direction: row;
        }
        &__text {
            margin-left: 12px;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #A9AEB2;
        }
        &__discount{
            min-width: 150px;
        }
    }
    &__cost-and-duration {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #000000;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;
    }
    &__places {
        position: relative;
        width: 100%;
        height: 40px;
        background: #EBEBEB;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 16px;
        &__background {
            position: absolute;
            height: 100%;
            background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%), #FFFFFF;
            overflow: hidden;
        }
        &__text {
            position: absolute;
            width: 320px;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &__white {
                color: #FFFFFF;
            }
            @media all and(max-width: 768px) {
                width: 320px;
            }
            @media all and(max-width: 1280px) {
                width: 300px;
            }
        }
    }
    &__prices {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 6px 16px;
        border-bottom: 1px solid #EBEBEB;
        // @media all and (max-width: 768px) {
        //     flex-direction: column;
        //     justify-content: flex-start;
        // }
        &__default-price{
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.02em;
            text-decoration-line: line-through;
            color: #9FA5AD;
            flex: none;
        }
        &__sale {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            @media all and (max-width: 768px) {
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
        &__percent {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45px;
            height: 22px;
            background: #3A4256;
            border-radius: 20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            margin-left: 6px;
        }
        &__final-price {
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: -0.02em;
            color: #3A4256;
            white-space: nowrap;
        }
    }
    &__booking {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid #EBEBEB;
        &__info {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            color: #000000;
            margin-bottom: 16px;
        }
        &__count-and-button {
            background: #FFFFFF;
            box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
            width: 100%;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            &__count {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px;
                &__text {
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    color: #000000;
                }
            }

        }
        &__cancel {
            margin-top: 10px;
            padding: 0 2px;
            font-size: 12px;
            line-height: 140%;
            text-align: center;
            letter-spacing: -0.02em;
            color: #A9AEB2;
        }
    }
    &__no_active-tours {
        padding: 10px 0;
        border-bottom: 1px solid #EBEBEB;
        &__text {
            font-weight: bold;
            font-size: 26px;
            line-height: 30px;
            letter-spacing: -0.02em;
            color: #3A4256;
            margin: 5px 0 15px;
        }
        &__download-link {
        position: absolute;
        width: 285px;
        height: 45px;
    }
    }
    &__organizer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: 16px 0;
        &__text {
            color: #3A4256;
            margin-left: 10px;
            &__name {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                letter-spacing: -0.02em;
                color: #3A4256;
                margin-bottom: 14px;
            }
            &__raiting {
                font-size: 14px;
                line-height: 15px;
                letter-spacing: -0.02em;
                vertical-align: center;
            }

        }
        &__avatar {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            // background: #EBEBEB;
        }
    }
    &__comment {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #A1A4B1;
        padding: 8px 6px;
    }
}
</style>